.carousel.reverse {
  overflow: hidden;
  white-space: nowrap;
}

.carousel.reverse .card {
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-right: 20px;
  border: 1px solid #0c4848;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  /* background-color: #fff; */
}

.carousel.reverse .card img {
  max-width: 100%;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
}

.carousel.reverse .rewards {
  background-color: #f5f878;
  color: black;
  padding: 5px;
  border-radius: 5px;
}