.info-box-1 {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
  
  .info-box-1-card {
    background-color: black;
    border-radius: 5px;
    padding: 15px;
    width: calc(33.33% - 20px);
    height: 100px;
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to bottom right, #ffd700, #ff8c00);
    margin-right: 20px; /* Add right margin between the cards */
  }
  
  .info-box-1-card:last-child {
    margin-right: 0; /* Remove right margin for the last card */
  }
  
  .info-box-1-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }
  
  .info-box-1-subheading {
    font-size: 16px;
    color: grey;
  }
  
  /* Mobile Version */
  @media (max-width: 767px) {
    .info-box-1 {
      flex-wrap: wrap;
    }
    
    .info-box-1-card {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0; /* Remove right margin in mobile version */
    }
  }
  