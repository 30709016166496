.about-us-container {
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
}

.about-us-heading {
    color: #333;
}

.about-us-content {
    margin-top: 20px;
}

.about-us-content p {
    margin-bottom: 15px;
    line-height: 1.6;
    color: #333;
    text-align: left;
    /* Left-align paragraphs */
}