@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate3d(0, 1, 0, 0deg);
  }
  50% {
    transform: translateY(-20px) rotate3d(0, 1, 0, 3deg);
  }
}

@keyframes blob {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(20px, -20px) scale(1.1);
  }
}

@keyframes blob2 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(-20px, 20px) scale(1.1);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

.animate-blob {
  animation: blob 7s infinite;
}

.animate-blob2 {
  animation: blob2 7s infinite;
}

.preserve-3d {
  transform-style: preserve-3d;
  perspective: 1000px;
}

.rotate-y-12 {
  transform: rotateY(12deg);
}

